import { Component, Input, OnChanges } from '@angular/core';
import { Table, RangeTest, LookupTest } from '../../_models/table';
import { SelectorsService, Selector } from '../../_services/selectors.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnChanges {
  inputLabel: string;
  changeStatus = false;

  constructor(private ss: SelectorsService) { }

  @Input() table: Table;
  @Input() editMode: boolean;

  ngOnChanges() {
    const selector = this.ss.getSelector(this.table.keyId);
    if (this.table.type === 'Formula') {
      this.inputLabel = this.table.formula?.name;
    } else {
      this.inputLabel = selector.label;
    }
  }

  calcRanges(ranges: RangeTest[]) {
    let lastFinish = -1;
    ranges.forEach(range => {
      range.rangeStart = this.calcIncrement(lastFinish);
      lastFinish = range.rangeFinish;
    });
  }

  addRow(ranges: RangeTest[]) {
    if (ranges === undefined) {
      this.table.rangeTests = [{
        rangeStart: 0,
        rangeFinish: 0,
        result: 0,
        order: 1
      }];
    } else {
      const lastRange = ranges[ranges.length - 1];
      const aRange: RangeTest = {
        rangeStart: this.calcIncrement(lastRange.rangeFinish),
        rangeFinish: this.calcIncrement(lastRange.rangeFinish),
        result: 0,
        order: lastRange.order + 1
      };
      ranges.push(aRange);
    }
  }

  calcIncrement(value: number): number {
    const decimals = ((value % 1) !== 0) ? value.toString().split('.')[1].length : 0;
    const decimalPower = (10.0 ** decimals);

    return Math.round(decimalPower * (value + (1 / decimalPower))) / decimalPower;
  }

  deleteRow(ranges: RangeTest[], index: number) {
    if (ranges.length > 1) {
      ranges.splice(index, 1);
    } else {
      alert('Cannot delete last row');
    }
  }

  changeInput(state: 'edit' | 'save' | 'cancel') {
    switch (state) {
      case 'edit':
        this.changeStatus = true;
        break;
      case 'cancel':
        this.changeStatus = false;
        break;
      case 'save':
        this.changeStatus = false;
        if ((this.table.type === 'Formula' && this.table.formula !== undefined) || this.table.keyId !== undefined) {
          if (this.table.type === 'Formula') {
            this.table.keyId = null;
            this.inputLabel = this.table.formula.name;
          } else {
            const selector = this.ss.getSelector(this.table.keyId);
            this.inputLabel = selector.label;
          }
          // remove previous table if there
          this.table.lookupTests = [];
          // if a multiple-choice question then build new lookup table
          if (this.table.type === 'Question' && this.table.question.format === 'Code') {
            this.table.rangeTest = false;
            this.table.question.choices.forEach(choice => {
              const aTest: LookupTest = {
                // TODO: Converted tables don't have label
                // label: choice.label,
                lookupValue: choice.value,
                result: 0,
                order: this.table.lookupTests.length
              };
              this.table.lookupTests.push(aTest);
            });

          } else {
            // if it's not a lookup table then it has to be a range table
            this.table.rangeTest = true;
            this.addRow(this.table.rangeTests);
          }
        }
        break;
    }

  }

}
