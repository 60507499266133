import { Injectable } from '@angular/core';
import { Question, SchoolQuestions } from '../_models/question';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { tap, take } from 'rxjs/operators';

import { find, differenceBy } from 'lodash-es';
import { convertTimestampsPipe } from 'app/_pipes/convert-firestore-timestamp.pipe';
import { AuthService } from './auth.service';

// In 2023, we started to move from the collection questions_draft to calcConfig/schoolId/questions/aidYear.
// The aidYear questions SHOULD be the same as the questions_draft, but we need to make sure.
// TODO: Then move this application and the student version to use the calcConfig/schoolId/questions/aidYear.
@Injectable()
export class QuestionsService {

  draftQuestions = new BehaviorSubject<Question[]>([]);
  draftLastUpdated = new BehaviorSubject<Date | null>(null); // Tracked using the aidYearQuestionsSubscription
  numericDraftQuestions = new BehaviorSubject<Question[]>([]); // Can be used as a selector for numeric questions
  allQuestions = new BehaviorSubject<Question[]>([]);
  unusedQuestions = new BehaviorSubject<Question[]>([]); // Used to populate the Add Question dropdown



  // Track the school and aid year of the last time we loaded questions
  loadedSchoolId = '';
  loadedAidYear: number | null = null;
  allQuestionsLoadedAidYear: number | null = null;

  // Track subscriptions so we can unsubscribe
  draftSubscription: Subscription;
  aidYearQuestionsSubscription: Subscription;
  allQuestionsSubscription: Subscription;


  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { }

  // We need to load all questions for the aid year and the draft questions for the school
  public loadQuestions(schoolId: string, aidYear: number) {
    // Load master list of all questions if not already loaded for this aid year
    if (aidYear !== this.allQuestionsLoadedAidYear) {
      this.allQuestions.next([]);
      this.loadAllQuestions(aidYear);
    }

    // If the schoolId and aidYear are the same as the last time we loaded questions, don't do it again
    if (schoolId === this.loadedSchoolId && aidYear === this.loadedAidYear) {
      return;
    }

    this.draftQuestions.next([]);
    this.numericDraftQuestions.next([]);
    if (this.draftSubscription) { this.draftSubscription.unsubscribe(); }

    this.draftSubscription = this.afs.doc<any>('questions_draft/' + schoolId).valueChanges().pipe(
      convertTimestampsPipe(),
      tap(res => {
        if (res) {
          console.log(schoolId, this.draftQuestions.value.length === 0 ? 'loaded' : 're-loaded', res.questions.length, 'questions for aid year', aidYear);
          this.draftQuestions.next(res.questions);
          this.numericDraftQuestions.next(
            res.questions.filter(q => q.format === 'Number' || q.format === 'Integer' || q.format === 'NumberAllowNegative')
          );
          this.addDependencyQuestion();
          this.populateUnusedQuestions();  // This is performed here AND when loading all questions to make sure it is up to date
          this.loadedSchoolId = schoolId;
          this.loadedAidYear = aidYear;
        } else {
          alert('School Questions have not been converted.');
        }
      })
    ).subscribe();

    if (this.aidYearQuestionsSubscription) { this.aidYearQuestionsSubscription.unsubscribe(); }
    this.aidYearQuestionsSubscription =     // Subscribe to questions document
      this.afs.doc<SchoolQuestions>('calcConfig/' + schoolId + '/questions/' + aidYear).valueChanges().pipe(
        convertTimestampsPipe(),
        tap(res => {
          if (res?.lastUpdated) {
            console.log(res?.lastUpdated, 'last updated')
            this.draftLastUpdated.next(res.lastUpdated);
          } else {
            this.draftLastUpdated.next(null);
          }
        })
      ).subscribe();
  }

  populateUnusedQuestions() {
    // const extraList = differenceBy(this.allQuestions.value, this.draftQuestions.value, 'id').filter(q => q.triggerQuestionId === undefined);
    // Show triggerred questions now and then don't allow user in component to add them directly (need to use triggering question)
    // TODO: Flag triggered questions as not addable if their triggering question is in this list
    const extraList = differenceBy(this.allQuestions.value, this.draftQuestions.value, 'id');
    this.unusedQuestions.next(extraList);
  }

  async saveQuestions(schoolId, questions: Question[], aidYear: number) {
    const user = await this.auth.currentUser();
    const schoolQuestions: SchoolQuestions = {
      schoolId: schoolId,
      aidYear: aidYear,
      questions: questions,
      lastUpdated: new Date(Date.now()),
      lastUpdatedBy: user.uid
    };
    // This updates the aid year directly.  If we switch the student app to use this then we are automatically publishing the questions.
    // We would need to add a live version of the questions in this collection that the student app would use.
    // or keep the questions collection for the student app and the current publishquestions method.
    this.afs.doc('calcConfig/' + schoolId + '/questions/' + aidYear).set(schoolQuestions);
    this.afs.doc('questions_draft/' + schoolId).set({ questions: questions });
    console.log('Updated ', questions.length, ' Questions');
  }

  // Called from Inspector Component when Publishing Rules
  public async publishQuestions(schoolId: string): Promise<string> {
    const questions = this.draftQuestions.value;
    try {
      await this.afs.doc('questions/' + schoolId).set({ questions: questions });
    } catch (err) {
      return 'Contact Support - Questions not published: ' + err;
    }
    return 'Questions successfully published';
  }
  // Called from Inspector Component when Resetting Rules
  public async resetQuestions(schoolId: string) {
    const doc = await this.afs.doc('questions/' + schoolId).ref.get();
    if (doc.exists) {
      // Make a backup copy of the draft questions (should have the lastest date updated in hte backup folder )
      const draftQuestions = this.draftQuestions.value;
      const user = await this.auth.currentUser();
      const schoolQuestions: SchoolQuestions = {
        schoolId: schoolId,
        aidYear: this.loadedAidYear,
        questions: draftQuestions,
        lastUpdated: new Date(Date.now()),
        lastUpdatedBy: user.uid
      };
      await this.afs.collection<SchoolQuestions>('calcConfig_backup').doc(schoolId).collection('questions').add(schoolQuestions);

      const questions = doc.data();
      await this.afs.doc('questions_draft/' + schoolId).set(questions);
    }
  }


  addDependencyQuestion() {
    const dq = find(this.draftQuestions.value, { 'id': 'student.dependencyStatus' });
    if (!dq) {
      const q: Question = {
        section_id: 'DS',
        format: 'Code',
        id: 'student.dependencyStatus',
        label: 'Calculated Dependency Status',
        ordinal: 1,
        choices: [{ label: 'Dependent', value: 'Dependent', ord: 1 }, { label: 'Independent', value: 'Independent', ord: 2 }],
        help: '',
        value: '',
        hidden: true,
        indent: false,
        required: false,
        dependent: true,
        independent: true,
        methodRequired: true
      };
      const questions = this.draftQuestions.value;
      questions.push(q);
      this.draftQuestions.next(questions);
    }
  }


  getQuestion(id: string): Question {
    return this.draftQuestions.value.filter(obj => obj.id === id)[0];
  }

  async getAllQuestionsForAidYear(aidYear: number): Promise<Question[]> {
    const questions = await firstValueFrom(this.afs.doc<any>('allQuestions/' + aidYear).valueChanges());
    return questions?.questions || [];
  }


  loadAllQuestions(aidYear: number) {

    if (this.allQuestionsSubscription) { this.allQuestionsSubscription.unsubscribe(); }

    this.allQuestionsSubscription = this.afs.doc<any>('allQuestions/' + aidYear).valueChanges().pipe(
      take(1),
      convertTimestampsPipe(),
      tap(res => {
        if (res) {
          console.log('All', res.questions.length, 'Questions loaded for aid year', aidYear);
          this.allQuestionsLoadedAidYear = aidYear;
          this.allQuestions.next(res.questions);
          this.populateUnusedQuestions();
        } else { alert('The master list of all questions for aid year ' + aidYear + ' not found'); }
      })
    ).subscribe();
  }

  saveAllQuestions(questions: Question[], aidYear: number) {
    const cleanQuestions = questions.filter(q => q.id); // Not needed anymore, but doesn't hurt
    // this.afs.doc('questions/default').set({ questions: cleanQuestions });
    this.afs.doc('allQuestions/' + aidYear).set({ questions: cleanQuestions });
  }


  public flagDraftQuestions(schoolId: string, institutionalMethod: boolean) {

    if (this.loadedSchoolId !== schoolId) {
      const accept = confirm('No questions exist, continue with adding initial questions?');
      if (!accept) {
        return;
      }
    }

    const im = institutionalMethod;

    const allQuestions: Question[] = this.allQuestions.value;
    const questions = this.draftQuestions.value;
    questions.forEach(q => {
      const newQuestion = find(allQuestions, { 'id': q.id });
      if (newQuestion) {

        const methodRequired = im ? newQuestion.imRequired || false : newQuestion.fmRequired || false;
        if (methodRequired !== q.methodRequired) {
          console.log('Changing question to Method Required =', methodRequired, 'for question', q.id);
          q.methodRequired = methodRequired;
        }
      } else {
        console.log('Quetion missing from all questions', q.id);
      }
    });

    // Find any questions that are required in the other methodology that were not there and add them
    const extraList = differenceBy(allQuestions, this.draftQuestions.value, 'id');
    const addQuestions = extraList.filter(q => im ? q.imRequired === true : q.fmRequired === true);
    addQuestions.forEach(q => {
      console.log('Adding required Question', q.id);
      q.methodRequired = true;
      questions.push(q);
    });

    this.saveQuestions(schoolId, questions, this.loadedAidYear);
  }


}
