<div style="padding: 2px">
  <mat-card appearance="outlined">
    <div *ngIf="!editMode">
      <mat-card-header>
        <mat-card-title>
          <h2>{{pop.name}}</h2>
        </mat-card-title>
        <mat-card-subtitle>{{pop.description}}</mat-card-subtitle>
      </mat-card-header>
    </div>
    <div *ngIf="editMode">
      <mat-card-header>
        <mat-card-title>
          <h2>
            Edit Population
          </h2>
        </mat-card-title>
      </mat-card-header>
      <mat-form-field style="margin-left: 1rem;width: 30%;">
        <input matInput placeholder="Name" type="text" [value]="pop.name" [(ngModel)]="pop.name">
      </mat-form-field>
      <mat-form-field style="margin-left: 1rem;width: 70%">
        <input matInput placeholder="Description" type="text" [value]="pop.description" [(ngModel)]="pop.description">
      </mat-form-field>
      <div class="fake-mat-quill" [ngStyle]="{'padding-top': pop.nextStep ? '0rem' : '1rem'}">
        <span *ngIf="pop.nextStep" style="top:0px;font-size:75%">
          Next steps text
        </span>
        <quill-editor [modules]="modules" placeholder="(Optional) Next Steps text..." theme="bubble" bounds="self" [(ngModel)]="pop.nextStep"></quill-editor>
      </div>
    </div>
    <hr>

    <mat-card-content style="margin-left: 1rem;">
      <div *ngIf="pop.nextStep && !editMode">
        <u>Next step</u><br>
        <span [innerHTML]="pop.nextStep"></span>
        <hr>
      </div>
      <!-- Population Header for Multiple Blocks -->
      <div *ngIf="pop.blocks.length > 1">

        <div *ngIf="!editMode">
          {{pop.blocks.length}} blocks joined by
          <small>{{pop.groupOperatorAnd === true ? 'AND' : 'OR'}}</small> condition
        </div>
        <div *ngIf="editMode">
          <h3>Multiple Block Logic</h3>
          <label id="multi-block-join-condition">Select logic for {{pop.blocks.length}} blocks: </label>
          <mat-radio-group [(ngModel)]="pop.groupOperatorAnd" role="radiogroup" aria-labelledby="multi-block-join-condition" class="block-choices">
            <mat-radio-button aria-label="All Blocks must be true" [value]="true" class="radio-option">All Blocks must
              be true</mat-radio-button>
            <mat-radio-button aria-label="Any Block may be true" [value]="false" class="radio-option">Any Block may be
              true</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <!-- Display a Block -->
      <div *ngFor="let block of pop.blocks; let blockIndex = index">
        <!-- Block Header for Multiple Conditions -->
        <div *ngIf="block.conditions.length > 1 || (pop.blocks.length > 1 && block.conditions.length > 0)" style="margin-bottom: 1rem;">
          <div *ngIf="!editMode">
            Block Condition: {{block.blockOperatorAnd === true ? 'All have to' : 'Any can'}} be true
          </div>

          <div *ngIf="editMode">
            <span class="block-title">
              <h3>Block {{blockIndex + 1}}</h3>
              <button mat-button style="margin-left: 2rem;" (click)="addBlock(pop)">add block</button>
              <button mat-button *ngIf="pop.blocks.length > 1" (click)="pop.blocks.splice(blockIndex,1)">remove block</button>
            </span>
            <label id="block-join-condition">Select condition logic for block {{blockIndex + 1}}: </label>
            <mat-radio-group [(ngModel)]="block.blockOperatorAnd" role="radiogroup" aria-labelledby="block-join-condition" class="block-choices">
              <mat-radio-button aria-label="All Blocks must be true" [value]="true" class="radio-option">All Conditions
                must be true</mat-radio-button>
              <mat-radio-button aria-label="Any Block may be true" [value]="false" class="radio-option">Any Condition
                may be true</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <!-- Block Contents -->
        <div [ngClass]="{'pop-block': pop.blocks.length > 1}">
          <table class="table" style="width:auto">
            <thead>
              <tr>
                <th scope="col">Question/Input</th>
                <th scope="col">Operator</th>
                <th scope="col">Assertion</th>
              </tr>
            </thead>
            <ng-container *ngFor="let cond of block.conditions; let condIndex = index">
              <!-- Display a Condition -->
              <tr *ngIf="editCondition !== cond" (click)="editCondition = editMode ? cond : null">

                <td>
                  <div *ngIf="cond.type === 'Question'">
                    {{ cond.question?.label || cond.keyId }}
                  </div>
                  <div *ngIf="cond.type === 'Formula'">
                    Formula ({{cond.formulaId}}): <strong>{{cond.formula.name}}</strong>
                    <br> <small>{{cond.formula.description}}</small>
                  </div>
                  <div *ngIf="cond.type === 'Calculation'">
                    {{getSelectorName(cond.keyId)}}
                  </div>
                </td>
                <td>
                  {{conditionMap[cond.operator]}}
                </td>
                <td>

                  <div *ngIf="cond.question?.format === 'Code'">
                    <select class="custom-select" multiple disabled [ngModel]="cond.values">
                      <option *ngFor="let choice of cond.question.choices" [value]="choice.value">{{choice.label}}
                      </option>
                    </select>
                  </div>

                  <div *ngIf="cond.operator === 6">
                    {{cond.value}} and {{cond.value2}}
                  </div>

                  <div *ngIf="cond.operator >= 0 && cond.operator <= 5 && cond.question?.format !== 'Code'">
                    {{cond.value}}
                  </div>
                </td>
              </tr>

              <!-- Edit a Condition -->
              <tr *ngIf="editCondition === cond">
                <!-- Question/Calculation/Formula Selector -->
                <td>
                  <input-selector [key]="cond" [caller]="'Population'"></input-selector>
                </td>
                <!-- Select condition operator -->
                <td>
                  <mat-form-field style="width: 8rem;">
                    <mat-select [(value)]="cond.operator" placeholder="Operator" *ngIf="cond.question?.format !== 'Code' || cond.type !== 'Question'">
                      <mat-option *ngFor="let operator of conditionMap; let i = index" [value]="i">{{operator}}
                      </mat-option>
                    </mat-select>
                    <mat-select [(value)]="cond.operator" placeholder="Operator" *ngIf="cond.question?.format === 'Code' && cond.type === 'Question'">
                      <mat-option [value]="0">{{conditionMap[0]}}</mat-option>
                      <mat-option [value]="5">{{conditionMap[5]}}</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="cond.operator === 6">(inclusive)</mat-hint>
                  </mat-form-field>
                </td>
                <!-- Enter value, values, or multi-select choices -->
                <td>
                  <!-- Show question choices if type is a question with a format of code -->
                  <div *ngIf="cond.question?.format === 'Code' && cond.type === 'Question'">
                    <select class="custom-select" multiple [(ngModel)]="cond.values">
                      <option *ngFor="let choice of cond.question.choices" [value]="choice.value">{{choice.label}}
                      </option>
                    </select>
                  </div>

                  <!-- Show yes/no choices if type is a question with a format of Boolean -->
                  <div *ngIf="cond.question?.format === 'Boolean' && cond.type === 'Question'">
                    <select class="custom-select" [(ngModel)]="cond.value" (change)="cond.values=null">
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <!-- Show two values if operator is between -->
                  <div *ngIf="cond.operator === 6">
                    <mat-form-field [hideRequiredMarker]="true" class="form-field">
                      <input matInput placeholder="From Value" type="number" [value]="cond.value" [(ngModel)]="cond.value">
                      <mat-error>Please enter a value</mat-error>
                    </mat-form-field>
                    and
                    <mat-form-field [hideRequiredMarker]="true" class="form-field">
                      <input matInput placeholder="To Value" type="number" [value]="cond.value2" [(ngModel)]="cond.value2">

                      <mat-error>Please enter a value</mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Show a single value if the operator is not between and the question is not a code or boolean-->
                  <div *ngIf="cond.operator !== 6 && ((cond.question?.format !== 'Code' && cond.question?.format !== 'Boolean') || cond.type !== 'Question')">
                    <mat-form-field [hideRequiredMarker]="true" class="form-field">
                      <input matInput placeholder="Value" type="number" [value]="cond.value" [(ngModel)]="cond.value">
                      <mat-error>Please enter a value</mat-error>
                    </mat-form-field>
                  </div>

                </td>
                <button style="margin-top:1rem;" mat-button color="warn" (click)="removeCondition(pop, blockIndex, condIndex)">remove condition</button>

              </tr>
            </ng-container>
            <tr>
              <td>
                <button *ngIf="editMode" mat-button (click)="addCondition(block)">add condition</button>
              </td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>


      </div>


    </mat-card-content>
  </mat-card>
</div>