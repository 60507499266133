<div class="title-bar">
  <h1>TABLES</h1>
  <app-help screen="tables"></app-help>
  <ng-container *ngIf="!editMode">
    <button mat-flat-button color="primary" [disabled]="tabIndex === 0" (click)="setMode('edit')">
      {{tabIndex == 0 ? 'Select Table to Edit' : 'Edit Table'}}
    </button>
  </ng-container>
  <ng-container *ngIf="editMode">
    <button mat-flat-button color="accent" [disabled]="tabIndex === 0" (click)="setMode(delete.checked ? 'delete' : 'save')">
      <span *ngIf="delete.checked">Delete Table</span>
      <span *ngIf="!delete.checked">Save Table</span>
    </button>
    <button mat-flat-button color="warn" (click)="setMode('cancel')">Cancel</button>
    <mat-checkbox style="margin-left: 2rem;" #delete>Delete Table</mat-checkbox>
  </ng-container>
  <app-aid-year style="margin-left:auto;" [disabled]="editMode"></app-aid-year>
</div>

<mat-tab-group mat-stretch-tabs="false" dynamicHeight [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)">
  <mat-tab label="Listing">
    <mat-card appearance="outlined">

      <div *ngIf="cc.tables | async as tables">
        <div *ngIf="tables.length > 0">
          <table class="table table-hover" style="width:auto">
            <thead>

              <th scope="col">Table</th>
              <th scope="col">Input</th>
              <th scope="col">Usage
                <br>
                <span class="legend-population">Population</span>
                <span class="legend-cost">Cost</span>
                <br>
                <span class="legend-package">Package</span>
                <span class="legend-formula">Formula</span>
              </th>

            </thead>
            <tbody>

              <tr *ngFor="let table of tables; let i = index" (click)="clickedRow(table)">
                <th scope="row">
                  <span class="link">{{table.name}}</span>
                  <div class="description" [innerHTML]="table.description"></div>
                </th>
                <td>
                  <div *ngIf="table.type === 'Question' || table.type === 'Calculation'">
                    {{getSelectorName(table.keyId)}}
                  </div>
                  <div *ngIf="table.type === 'Formula'">
                    Formula ({{table.formulaId}}):
                    <strong>{{table.formula?.name}}</strong>
                    <br>
                    <small>{{table.formula?.description}}</small>
                  </div>
                </td>
                <td>
                  <app-usage entityType="Table" [entityId]="table.tableId"></app-usage>
                </td>
              </tr>
              <tr>
                <td>
                  <button mat-button color="secondary" (click)="addTable(tables)">add new table</button>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>
        <div *ngIf="tables.length === 0">
          There are no tables <button mat-button color="secondary" (click)="addTable(tables)">add new table</button>
        </div>
      </div>
    </mat-card>
  </mat-tab>

  <mat-tab [label]="tableName" [disabled]="tableName === ''">
    <app-table *ngIf="tableName !== '' && !editMode" [table]="table" [editMode]="editMode"></app-table>
    <app-table *ngIf="tableName !== '' && editMode" [table]="tableCopy" [editMode]="editMode"></app-table>
  </mat-tab>

</mat-tab-group>