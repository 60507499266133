import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AidYearChangeService } from '_services/aid-year-change.service';
import { CalcConfigService } from '_services/calc-config.service';
import { SelectorChangesDialogComponent } from '_shared/selector-changes-dialog/selector-changes-dialog.component';
import { AidYearChange } from 'app/_models/calcPackage';
import { Subscription } from 'rxjs';

interface Option {
  name: string;
  value: number;
  hide: boolean;
}

const AIDYEAR_ICON =
  `
  <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M142-607v-208h60v106q52-61 123.5-96T477-840q151 0 257 104t106 254v10h-60v-13q0-124-89-209.5T477-780q-68 0-127.5 31T246-667h105v60H142Zm-22 126h60q0 125 86 213t211 88h12l34 57q-11 2-22.5 2.5t-23.5.5q-149 0-253-105.5T120-481Zm440 119L451-469v-214h60v189l81 79-32 53ZM735-10l-10-66q-18-5-34-13.5T661-112l-55 12-25-42 47-44q-3-12-3-25t3-25l-47-44 25-42 55 12q14-14 30-22.5t34-13.5l10-66h54l10 66q18 5 34 13.5t30 22.5l55-12 25 42-47 44q3 12 3 25t-3 25l47 44-25 42-55-12q-14 14-30 22.5T799-76l-10 66h-54Zm27-121q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22Z"/></svg>
`;

@Component({
  selector: 'app-aid-year',
  templateUrl: './aid-year.component.html',
  styleUrls: ['./aid-year.component.css']
})
export class AidYearComponent implements OnInit, OnDestroy {
  ruleSubscription: Subscription;
  selectedAidYear: number;
  aidYearOptions: Option[] = [
    { name: '2025-2026', value: 2526, hide: false },
    { name: '2024-2025', value: 2425, hide: false },
    // TODO: Around Decebmer 2024 this should be removed and only leave two options
    // { name: '2023-2024', value: 2324, hide: false },
  ];


  constructor(
    public cc: CalcConfigService,
    private ayc: AidYearChangeService,
    private dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral('aid-year', sanitizer.bypassSecurityTrustHtml(AIDYEAR_ICON));
  }
  @Input() disabled: boolean = false;

  ngOnInit() {
    this.ruleSubscription = this.cc.draftRuleInfo.subscribe((draftRuleInfo) => {
      if (draftRuleInfo?.aidYear) {
        // If the aid year is not found in the list of options, add it to the list of options
        if (!this.aidYearOptions.find((option) => option.value === draftRuleInfo.aidYear)) {
          this.aidYearOptions.push({ name: '20' + draftRuleInfo.aidYear.toString().substring(0, 2) + '-20' + draftRuleInfo.aidYear.toString().substring(2, 4) + '(x)', value: draftRuleInfo.aidYear, hide: false });
        }

        this.selectedAidYear = draftRuleInfo.aidYear;

        // hide the aid year 2526 if the draft rule info aid year is less than 2425
        this.aidYearOptions.find((option) => option.value === 2526).hide = draftRuleInfo.aidYear < 2425;


      } else {
        // If rules exist and the aid year is not found, set the aid year to the what is found in the preferences
        // This is a fallback in case the aid year is not found in the draft rule info
        if (draftRuleInfo) { this.ayc.setDraftAidYearFromPreferences() }
        else { alert('No draft rule info found - contact support'); }
      }
    });
  }

  selectionChange() {
    this.ayc.switchAidYear(this.selectedAidYear);

  }

  openDialog(aidYearChanges: AidYearChange[]) {
    this.dialog.open(SelectorChangesDialogComponent, { data: aidYearChanges });
  }
  ngOnDestroy() {
    this.ruleSubscription.unsubscribe();
  }
}
